Covers:
  @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1

Displays a basic list of inventory units. Used for transaction types like
"Make Available", "Ship", "Quarantine". Others like the modification
transactions need a more complicated layout.

<template>
  <div class="card">
    <div class="card-header">
      <h2>Total Component(s): <strong>{{ componentCount }}</strong></h2>
    </div>

    <div class="card-body">
      <div class="grid-table">
        <div class="head row">
          <div class="col-3">Unit Number</div>
          <div class="col-3">Product Code</div>
          <div class="col-2">Blood Type</div>
          <div class="col-3">Expiration Date</div>
          <div class="col-1"></div>
        </div>

        <div class="body">
          <barcode :transaction-type="transactionType" :inventoryUnit="first"
            @complete="$emit('next-item')" />

          <item v-for="(item, index) in rest" :item="item" :index="index+1"
            :key="`${item.unit_number}-${item.product_code}`"
            @delete-item="$emit('delete-item', $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from './item'
import Barcode from './barcode'

export default {
  props: ['items', 'transactionType'],
  emits: ['next-item', 'delete-item'],
  computed: {
    componentCount() { return this.items.filter(i => i.isPopulated()).length },
    first() { return this.items[0] },
    rest() { return this.items.slice(1) }
  },
  components: { Item, Barcode },
}
</script>

Covers
  @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Renders a multi-select list for UDF/custom fields. Requires `fieldSchema' and `name'

<template>
  <div class="field-group">
    <input :disabled="$attrs.disabled" :name="inputName" type="hidden" value="" autocomplete="off" />
    <select class="custom-select" multiple="multiple" v-bind="$attrs" :name="inputName"
      @change="$emit('update:modelValue', selections)" v-model="selections">
      <option v-for="optionValue in optionValues" :key="optionValue" :value="optionValue" >
        {{ optionValue }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['optionValues', 'modelValue', 'name'],
  emits: ['update:modelValue'],
  data() {
    const values = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue].filter(x => x)

    return {
      selections: values
    }
  },
  computed: {
    inputName() { return `${this.name}[]` },
  },
}
</script>

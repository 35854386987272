import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { label: "Save" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AvailableFieldPicker = _resolveComponent("AvailableFieldPicker")
  const _component_FieldSchemaList = _resolveComponent("FieldSchemaList")
  const _component_SingleFieldSchema = _resolveComponent("SingleFieldSchema")
  const _component_icon = _resolveComponent("icon")

  return (_openBlock(), _createElementBlock("form", {
    class: "user-defined-field",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.submitForm && $options.submitForm(...args)), ["prevent"]))
  }, [
    (!$data.userDefinedField.id)
      ? (_openBlock(), _createBlock(_component_AvailableFieldPicker, {
          key: 0,
          onInput: $options.availableFieldChanged,
          availableFields: $props.availableFields
        }, null, 8 /* PROPS */, ["onInput", "availableFields"]))
      : _createCommentVNode("v-if", true),
    ($data.userDefinedField.field_name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($options.fieldIsList)
            ? (_openBlock(), _createBlock(_component_FieldSchemaList, {
                key: 0,
                "field-schema-array": $data.userDefinedField.field_schema,
                "option-lists": $props.optionLists
              }, null, 8 /* PROPS */, ["field-schema-array", "option-lists"]))
            : (_openBlock(), _createBlock(_component_SingleFieldSchema, {
                key: 1,
                "field-schema": $data.userDefinedField.field_schema,
                "onUpdate:fieldSchema": _cache[0] || (_cache[0] = $event => (($data.userDefinedField.field_schema) = $event)),
                "option-lists": $props.optionLists,
                onDeleteSchema: $options.deleteFieldSchema,
                onInitSchema: $options.initFieldSchema
              }, null, 8 /* PROPS */, ["field-schema", "option-lists", "onDeleteSchema", "onInitSchema"]))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("mwc-fab", _hoisted_2, [
      _createVNode(_component_icon, { slot: "icon" })
    ])
  ], 32 /* HYDRATE_EVENTS */))
}
Covers
  @srs_3.1

A single audit entry in the audit trail. Multiple attributes may be changed
at once but this represents a single transactional change of an object.

<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-9 col-xl-6">
      <div class="card my-2">
        <div class="card-body">
          <h4 class="card-title">
            {{ username }}
            <span :class="actionClass">{{ actionDesc }}</span>
            {{ recordTitle }}
          </h4>

          <div class="media">
            <figure class="figure mr-3 mb-0 text-center">
              <img :src="photoUrl" class="figure-img img-fluid" alt="Profile Photo" />
              <figcaption class="figure-caption"><small>{{ formattedChangedAt }}</small></figcaption>
            </figure>

            <div class="media-body">
              <pre v-if="audit.comment" class="font-weight-bold">
                {{ audit.comment }}
              </pre>

              <component v-for="change in audit.changes"
                :is="changeTypeFor(change)" :key="change.attr" :change="change" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  figure { max-width: 64px }
</style>

<script>
import { formatTimestamp } from 'date_time_format'
import placeholder from 'images/profile-default.png'

import ChangeMessage from './change/message'
import ChangeSet from './change/set'
import ChangeUpdate from './change/update'
import ChangeWas from './change/was'

export default {
  props: ['audit', 'ownerType'],
  methods: {
    changeTypeFor(change) {
      if( change.message )
        return 'ChangeMessage'
      else if( this.audit.action == 'destroy' )
        return 'ChangeWas'
      else if( !change.before )
        return 'ChangeSet'
      else
        return 'ChangeUpdate'
    },
  },
  computed: {
    formattedChangedAt() {
      return formatTimestamp(this.audit.changed_at)
    },

    username() {
      if( this.audit.user )
        return this.audit.user.name
      else
        return 'System'
    },

    // FIXME: Use photo from audit API once it is supported and only use
    // placeholder as a fallback.
    photoUrl() {
      return placeholder
    },

    actionClass() {
      switch( this.audit.action ) {
        case 'create':
          return 'text-success'
        case 'destroy':
          return 'text-danger'
        default:
          return 'text-primary'
      }
    },

    actionDesc() {
      switch( this.audit.action ) {
        case 'destroy':
          return 'deleted'
        default:
          return `${this.audit.action}d`
      }
    },

    recordTitle() {
      if( this.audit.auditable.type == this.ownerType ) {
        return this.audit.auditable.label
      } else {
        return `${this.audit.auditable.label}: ${this.audit.auditable.id}`
      }
    },
  },
  components: { ChangeMessage, ChangeSet, ChangeUpdate, ChangeWas },
}
</script>

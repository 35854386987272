Covers
  @srs_15.6

Individual order card to display summary of order details

<template>
  <card>
    <template #title>
      <span v-if="order.order_number">
        Order ID {{ order.order_number }}
      </span>
      <span v-else># Not Assigned</span>
    </template>

    <dl class="card-details-header">
      <dt>Organization</dt>
      <dd>{{ order.organization_name }}</dd>

      <dt>Delivery</dt>
      <dd>
        {{ order.delivery }}

        <span v-if="order.delivery == 'STAT'" class="text-danger">
          <stat-icon />
        </span>
      </dd>

      <dt>State</dt>
      <dd>{{ order.state_name }}</dd>

      <dt>Order Type</dt>
      <dd>{{ order.order_type_name }}</dd>

      <dt v-if="showSampleStatus">Sample</dt>
      <dd v-if="showSampleStatus">{{ order.sample_id || 'Pending' }}</dd>

      <template v-if="order.sample">
        <udf-field v-for="field in order.sample.additional_information" v-bind="field" :key="field.id" />
      </template>
    </dl>

    <dl>
      <udf-field v-for="field in order.additional_information" v-bind="field" :key="field.id" />

      <template v-if="order.patient">
        <dt>Patient name</dt>
        <dd>{{ order.patient.name }}</dd>

        <dt>Blood type</dt>
        <dd>{{ order.patient.blood_type }}</dd>

        <udf-field v-bind="order.patient.significant_antibodies" />
        <udf-field v-bind="order.patient.insignificant_antibodies" />
        <udf-field v-for="field in order.patient.additional_information" v-bind="field" :key="field.id" />
      </template>

      <dt>Customer</dt>
      <dd>{{ order.customer_name }}</dd>
    </dl>

    <table v-if="hasRequestedProducts" class="mx-0 table table-sm align-middle table-borderless table-vcenter">
      <thead><tr>
        <th>QTY</th>
        <th>Type</th>
        <th>Product</th>
      </tr></thead>

      <tbody>
        <tr v-for="product in order.requested_products">
          <td>{{ product.quantity }}</td>
          <td>{{ product.type }}</td>
          <td>{{ product.category }}</td>
        </tr>
      </tbody>
    </table>

    <view-button :href="`orders/${order.id}`" class="btn-block" />
  </card>
</template>

<script>
import ViewButton from 'bootstrap/view_button'
import Card from 'bootstrap/card'
import UdfField from 'custom_field_schema/search_result_field'
import StatIcon from 'bootstrap-icons/icons/exclamation-triangle-fill.svg'

export default {
  props: ['order'],
  components: { ViewButton, Card, UdfField, StatIcon },
  computed: {
    hasRequestedProducts() {
      return this.order.requested_products && this.order.requested_products.length > 0
    },
    showSampleStatus() {
      return this.order.sample_required || this.order.sample_id
    }
  }
}
</script>

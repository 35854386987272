// Covers
//   @srs_7.1 @srs_7.2 @srs_7.4 @srs_8.1 @srs_9.1 @srs_12.1 @srs_14.1 @srs_14.2 @srs_14.5

import api from 'api'
import { formatTimestamp } from 'date_time_format'

export default class {
  constructor(attributes={}) {
    this.reset()
    Object.assign(this, attributes)
  }

  isPopulated() { return this.unit_number || this.product_code || this.blood_type || this.expiration_at }
  isIdentifiable() { return this.unit_number && this.product_code }
  isSendable() { return this.isIdentifiable() && this.blood_type && this.expiration_at }

  payload() {
    let { unit_number, product_code, blood_type, expiration_at } = this
    return { unit_number, product_code, blood_type, expiration_at }
  }

  formattedExpirationAt() {
    if( this.expiration_at ) return formatTimestamp(this.expiration_at)
  }

  async validate(transactionType) {
    const [response, body] = await api(
      `${transactionType}/transactions/item_validation`,
      { body: { item: this.payload() }, allowedErrors: [422] }
    )

    this.errors = []
    if( !response.ok ) this.errors = body.errors

    this.validated = true
  }

  reset() {
    this.validated = false
    this.errors = []
    this.unit_number = null
    this.product_code = null
    this.blood_type = null
    this.expiration_at = null
  }
}

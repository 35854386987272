Covers:
  @srs_8.1 @srs_9.1 @srs_12.1 @srs_14.1 @srs_14.2 @srs_14.5

Common layout code between all transaction types.

<template>
  <div>
    <div v-if="$slots.actions" class="table-toolbar-right">
      <slot name="actions" />
    </div>

    <validate-only-form @submit="$emit('submit')">
      <panel>
        <slot name="params" />
      </panel>

      <slot />

      <mwc-fab label="Save" :disabled.attr="isSubmittable ? undefined : true">
        <icon slot="icon" />
      </mwc-fab>
    </validate-only-form>
  </div>
</template>

<script>
import 'theme/material'
import Panel from 'bootstrap/panel'
import ValidateOnlyForm from 'validate_only_form'
import Icon from 'bootstrap-icons/icons/check.svg'

export default {
  props: ['isSubmittable'],
  emits: ['submit'],
  components: { Panel, ValidateOnlyForm, Icon },
}
</script>

Covers
  @srs_23.1 @srs_24.1 @srs_25.1

Form field for code editing.

A wrapper around code-editor but with a hidden field so the code is submitted
with the form.

<template>
  <div>
    <input type="hidden" v-model="dValue" v-bind="$attrs" />
    <code-editor v-model="dValue" :height="height" />
  </div>
</template>

<script>
import CodeEditor from 'code_editor'

export default  {
  inheritAttrs: false,
  props: {
    value: {},
    height: { default: '15rem' },
  },
  components: { CodeEditor },
  data() { return { dValue: this.value } },
}
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "barcode" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = { class: "input-group-prepend" }
const _hoisted_6 = { class: "input-group-text" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "col-3" }
const _hoisted_10 = { class: "col-2" }
const _hoisted_11 = { class: "col-3" }
const _hoisted_12 = { class: "col-1 text-right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_errors = _resolveComponent("errors")
  const _component_icon = _resolveComponent("icon")
  const _component_overlay = _resolveComponent("overlay")
  const _component_delete_button = _resolveComponent("delete-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_errors, {
      errors: $props.inventoryUnit.errors
    }, null, 8 /* PROPS */, ["errors"]),
    _createElementVNode("div", {
      class: _normalizeClass({ 'bg-danger': $options.hasErrors, 'active': !$options.hasErrors })
    }, [
      _createVNode(_component_overlay, { show: $data.validating }, {
        default: _withCtx(() => [
          _createVNode(_component_overlay, { show: $data.searching }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, [
                        _createVNode(_component_icon)
                      ])
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      autofocus: "",
                      id: "barcode",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.value) = $event)),
                      onInput: _cache[1] || (_cache[1] = (...args) => ($options.check && $options.check(...args))),
                      onKeydown: _cache[2] || (_cache[2] = (...args) => ($options.discardControl && $options.discardControl(...args)))
                    }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
                      [_vModelText, $data.value]
                    ])
                  ])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["show"]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString($props.inventoryUnit.unit_number || '...'), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_9, _toDisplayString($props.inventoryUnit.product_code || '...'), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_10, _toDisplayString($props.inventoryUnit.blood_type || '...'), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_11, _toDisplayString($props.inventoryUnit.formattedExpirationAt() || '...'), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_delete_button, {
                onClick: _cache[3] || (_cache[3] = $event => ($props.inventoryUnit.reset()))
              })
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["show"])
    ], 2 /* CLASS */)
  ]))
}
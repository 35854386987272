Covers
  @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Renders a basic text input for a UDF/custom field.

Mixes in barcode functionality so barcoding can take place on single line text
fields.

<template>
  <input type="text" class="form-control" v-bind="$attrs" :value="modelValue"
    @keydown="onKeyDown" @input="onInput" />
</template>

<script>
import barcode from 'barcode/mixin'

export default {
  props: ['modelValue'],
  mixins: [barcode],
}
</script>

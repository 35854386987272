Covers
  @srs_15.5

Root component for the patients search screen. Displays list of patients.
Provides search options to filter list.

<template>
  <div>
    <conditional-wrapper :wrapper="panelComponent" :wrap="pathScope == ''">
      <div class="row">
        <search v-model="query.search">
          <ul class="list-unstyled">
            <li>Name</li>
            <li>Searchable Custom Fields</li>
          </ul>

          <p>
            Search dates using format mm/dd/yyyy or yyyy-mm-dd.
            Example 11/05/1977
          </p>
        </search>
      </div>
    </conditional-wrapper>

    <results initial-uri="patients?per_page=12" :query="query" ref="results" no-empty-query="true">
      <template #default="{ rows }">
        <div class="row row-cols-xl-3">
          <div v-for="row in rows" :key="row.id" class="col my-3">
            <item :patient="row" :path-scope="pathScope" :query="query" />
          </div>
        </div>
      </template>
    </results>

    <a v-if="queried" :href="`${pathScope}/patients/new`" class="d-block mt-3">Add New Patient</a>
  </div>
</template>

<script>
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'
import Search from 'index/filter/search'
import Item from './item'
import ConditionalWrapper from 'conditional_wrapper'
import Panel from 'bootstrap/panel'

export default {
  mixins: [QueryPersistence],
  props: {
    defaultSearch: { default: '' },
    pathScope: { default: '' },
  },
  data() {
    // If operating under a scope we don't have control over the whole page so
    // default search comes in via props. If we don't have scope then can use
    // querystring like rest of index pages.
    let query = { search: this.defaultSearch }
    if( this.pathScope == '' ) query = this.fromUrlWithDefaults(query)

    return {
      query,
      queried: !!query.search,
    }
  },
  components: {
    Results, Search, Item, ConditionalWrapper,
  },
  computed: {
    panelComponent() { return Panel },
  },
  watch: {
    // We don't show the option to create a new patient until they at least do
    // one query.
    query: {
      deep: true,
      handler() { this.queried = true },
    }
  }
}
</script>

// This file is generated by rake task `generate_icon_module`. Do not edit.

import ArrowCounterclockwise from 'bootstrap-icons/icons/arrow-counterclockwise'
import ArrowLeft from 'bootstrap-icons/icons/arrow-left'
import ArrowRepeat from 'bootstrap-icons/icons/arrow-repeat'
import AwardFill from 'bootstrap-icons/icons/award-fill'
import BoxArrowUpRight from 'bootstrap-icons/icons/box-arrow-up-right'
import CaretLeft from 'bootstrap-icons/icons/caret-left'
import CaretRight from 'bootstrap-icons/icons/caret-right'
import Check from 'bootstrap-icons/icons/check'
import Check2 from 'bootstrap-icons/icons/check2'
import ChevronDoubleDown from 'bootstrap-icons/icons/chevron-double-down'
import ClockHistory from 'bootstrap-icons/icons/clock-history'
import CloudArrowUp from 'bootstrap-icons/icons/cloud-arrow-up'
import ExclamationCircle from 'bootstrap-icons/icons/exclamation-circle'
import ExclamationTriangleFill from 'bootstrap-icons/icons/exclamation-triangle-fill'
import Filter from 'bootstrap-icons/icons/filter'
import Key from 'bootstrap-icons/icons/key'
import LayoutTextSidebar from 'bootstrap-icons/icons/layout-text-sidebar'
import Pencil from 'bootstrap-icons/icons/pencil'
import Plu from 'bootstrap-icons/icons/plus'
import PlusSquare from 'bootstrap-icons/icons/plus-square'
import Printer from 'bootstrap-icons/icons/printer'
import PrinterFill from 'bootstrap-icons/icons/printer-fill'
import SortDown from 'bootstrap-icons/icons/sort-down'
import TrashFill from 'bootstrap-icons/icons/trash-fill'
import Upload from 'bootstrap-icons/icons/upload'

export default {
  'arrow-counterclockwise': ArrowCounterclockwise,
  'arrow-left': ArrowLeft,
  'arrow-repeat': ArrowRepeat,
  'award-fill': AwardFill,
  'box-arrow-up-right': BoxArrowUpRight,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'check': Check,
  'check2': Check2,
  'chevron-double-down': ChevronDoubleDown,
  'clock-history': ClockHistory,
  'cloud-arrow-up': CloudArrowUp,
  'exclamation-circle': ExclamationCircle,
  'exclamation-triangle-fill': ExclamationTriangleFill,
  'filter': Filter,
  'key': Key,
  'layout-text-sidebar': LayoutTextSidebar,
  'pencil': Pencil,
  'plus': Plu,
  'plus-square': PlusSquare,
  'printer': Printer,
  'printer-fill': PrinterFill,
  'sort-down': SortDown,
  'trash-fill': TrashFill,
  'upload': Upload,
}

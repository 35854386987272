Covers:
  @srs_1.1 @srs_5.1 @srs_6.1 @srs_7.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4 @srs_22.1

Renders the provided content in a panel. Likely won't get too complicated as
raw markup is more clear at some point. Title can be provided (and is optional).
Usage:

  <panel>
    <template #title><h3>My Title</h3></template>

    My panel content
  </panel>

<template>
  <div class="card">
    <div v-if="$slots.title" class="card-header">
      <slot name="title" />
    </div>
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {}
</script>

Covers
  @srs_1.1 @srs_1.5 @srs_1.9 @srs_5.1 @srs_6.1 @srs_7.1 @srs_7.5 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_20.1 @srs_20.2 @srs_20.3 @srs_20.5 @srs_20.6 @srs_21.2 @srs_21.3 @srs_22.1 @srs_24.1

Puts a progress indicator on top of the slotted content. Usage:

    <overlay :show="should_show">
      Content
    </overlay>

Note this component creates a container for the slot which always exists
regardless if the overlay is showing or not. You can assign events to this
container.

<template>
  <div v-bind="$attrs" :class="{ overlay: enabled }">
    <slot />
  </div>
</template>

<style lang="scss">
  .overlay {
    position: relative; min-height: 7rem;
    &::before, &::after { display: block; content: ''; z-index: 10000 }
    &::before {
      position: absolute; top: 0; right: 0; bottom: 0; left: 0;
      background: rgba(255,255,255,0.8);
    }
    &::after {
      position: absolute; top: 3em; left: 50%; margin-left: -0.5em;
      width: 1em; height: 1em;
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-arrow-repeat' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M2.854 7.146a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L2.5 8.207l1.646 1.647a.5.5 0 0 0 .708-.708l-2-2zm13-1a.5.5 0 0 0-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708z'/%3E%3Cpath fill-rule='evenodd' d='M8 3a4.995 4.995 0 0 0-4.192 2.273.5.5 0 0 1-.837-.546A6 6 0 0 1 14 8a.5.5 0 0 1-1.001 0 5 5 0 0 0-5-5zM2.5 7.5A.5.5 0 0 1 3 8a5 5 0 0 0 9.192 2.727.5.5 0 1 1 .837.546A6 6 0 0 1 2 8a.5.5 0 0 1 .501-.5z'/%3E%3C/svg%3E");
    }
  }

  @keyframes spin {
    from { transform: scale(3) rotate(0deg) }
    to { transform: scale(3) rotate(360deg) }
  }
</style>

<script>
import IndicatorDelay from 'indicator_delay'

export default {
  props: ['show'],
  data() {
    return {
      enabled: this.show,
    }
  },
  watch: {
    'show': {
      handler(should_show, _was_showing) {
        if( should_show ) {
          this.delay = new IndicatorDelay(()=> this.enabled = true)
        } else {
          if( this.delay ) this.delay.cancel()
          this.enabled = false
        }
      },
    },
  },
}
</script>

Covers:
  @srs_3.1

Iterates through the list of formatters and renders the given audit change
value appropriately.

<template>
  <span v-if="change.html" v-html="this.value"></span>
  <div v-else-if="change.preformatted" style="white-space: pre-wrap">{{ formattedValue }}</div>
  <span v-else>{{ formattedValue }}</span>
</template>

<script>
import Null from './value/null'
import DateTime from './value/date_time'
import Default from './value/default'

// NOTE: Order matters. For example default should always be last
const formatters = [Null, DateTime, Default]

export default {
  props: ['change', 'value'],
  computed: {
    formattedValue() {
      for( let i = 0; i < formatters.length; i++ ) {
        let formatter = new formatters[i](this.value, this.change)
        if( formatter.canFormat() ) return formatter.formattedValue()
      }
      return null
    },
  },
}
</script>

Covers
  @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Must be provided `fieldName', and `fieldSchemas', and `recordParamKey'

<template>
  <div class="custom-field-list-item row">
    <div v-for="fieldSchema in fieldSchemaList" :key="fieldSchema.id" class="custom-field-wrapper col-md-6 col-lg-4">
      <custom-field :field-schema="fieldSchema" :old-field-schema="oldSchemaFor(fieldSchema)" :input-name="listInputName(fieldSchema)" :readonly="readonly" />
    </div>
  </div>
</template>

<script>
// Components
import CustomField from './custom_field'

export default {
  props: ['fieldSchemaList', 'oldFieldSchemaList', 'fieldName', 'recordParamKey', 'readonly'],
  methods: {
    listInputName(fieldSchema) {
      return [this.recordParamKey, `[${this.fieldName}_value]`, `[${fieldSchema.id}]`].join('')
    },

    oldSchemaFor(fieldSchema) {
      return this.oldFieldSchemaList?.find(s => s.id == fieldSchema.id)
    },
  },
  components: { CustomField },
}
</script>

// Covers
//   @srs_1.6

import { meta_value, logged_in } from 'meta_value'

async function check_login(failure_behavior) {
  if( !logged_in() ) return
  const response = await fetch('/sessions/active', { credentials: 'same-origin' })
  if( response.status === 401 ) failure_behavior()
}

export default (failure_behavior) => {
  // Read from meta element to allow better control while testing
  const poll_frequency = parseInt(meta_value('session_poll_frequency')) || 60

  return setInterval(()=> check_login(failure_behavior), poll_frequency * 1000)
}

Covers
  @srs_20.5

Configuration for specific field

<template>
  <div class="list-group-item">
    <delete-button @click="$emit('remove')" />

    <div class="row no-gutters">

      <div class="col-6 col-xl-4">
        <field label="Object">
          <select v-model="field.object" class="custom-select-sm">
            <option v-for="object in objects" :key="object[0]" :value="object[0]">
              {{ object[1] }}
            </option>
          </select>
        </field>
      </div>

      <div class="col-6 col-xl-4 pl-1">
        <field label="Field">
          <input type="text" v-model="field.field" class="form-control-sm" />
        </field>
      </div>

      <div class="col-6 col-xl-2 pl-xl-1">
        <field label="X">
          <input type="number" v-model="field.x" class="form-control-sm" />
        </field>
      </div>

      <div class="col-6 col-xl-2 pl-1">
        <field label="Y">
          <input type="number" v-model="field.y" class="form-control-sm" />
        </field>
      </div>

    </div>
  </div>
</template>

<script>
import Field from 'bootstrap/field'
import DeleteButton from 'bootstrap/delete_button'

export default {
  props: ['field', 'objects'],
  emits: ['remove'],
  components: { Field, DeleteButton },
}
</script>

<style scoped>
button[title="Delete"] { position: absolute; top: 0; right: 0 }
</style>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-panel-title", "data-backend-object"]
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "pr-4" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "col-auto" }
const _hoisted_8 = {
  key: 0,
  class: "badge badge-circle badge-light badge-text-success"
}
const _hoisted_9 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_complete_icon = _resolveComponent("complete-icon")
  const _component_styled_button = _resolveComponent("styled-button")
  const _component_collapse = _resolveComponent("collapse")
  const _component_overlay = _resolveComponent("overlay")

  return (_openBlock(), _createBlock(_component_overlay, { show: $data.loading }, {
    default: _withCtx(() => [
      ($data.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "self",
            class: _normalizeClass(["resource-panel-card card hook-scope-panel", { open: $data.open }]),
            "data-panel-title": $data.title,
            "data-backend-object": $props.backendObject,
            onRefresh: _cache[9] || (_cache[9] = _withModifiers((...args) => ($options.reload && $options.reload(...args)), ["stop"]))
          }, [
            _createElementVNode("div", {
              class: "card-header",
              onClick: _cache[1] || (_cache[1] = (...args) => ($options.togglePanel && $options.togglePanel(...args)))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h2", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString($data.title), 1 /* TEXT */),
                  ($data.subtitle)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_5, [
                        _createElementVNode("a", {
                          href: $data.subtitleLink,
                          target: "_blank",
                          class: "text-reset",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                        }, _toDisplayString($data.subtitle), 9 /* TEXT, PROPS */, _hoisted_6)
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  ($data.complete)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                        _createVNode(_component_complete_icon)
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                ($props.editUri)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_styled_button, {
                        circle: "true",
                        icon: "chevron-double-down",
                        "data-toggle": "card"
                      })
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_collapse, {
                onShowing: _cache[8] || (_cache[8] = $event => ($data.panelContent = $data.loadedContent))
              }, {
                default: _withCtx(() => [
                  (!$data.toggling)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "card-text",
                        "onHook:submit": _cache[2] || (_cache[2] = _withModifiers($event => ($data.loading = true), ["stop"])),
                        "onHook:cancelSubmit": _cache[3] || (_cache[3] = _withModifiers($event => ($data.loading = false), ["stop"])),
                        "onTurbo:submitStart": _cache[4] || (_cache[4] = (...args) => ($options.beforeRemoteForm && $options.beforeRemoteForm(...args))),
                        "onTurbo:beforeFetchResponse": _cache[5] || (_cache[5] = (...args) => ($options.afterRemoteForm && $options.afterRemoteForm(...args))),
                        onClick: _cache[6] || (_cache[6] = (...args) => ($options.handleLink && $options.handleLink(...args))),
                        onInput: _cache[7] || (_cache[7] = (...args) => ($options.markDirty && $options.markDirty(...args))),
                        innerHTML: $data.panelContent
                      }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_11))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_1))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show"]))
}
Covers
  @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

  Renders a checkbox array for a UDF/custom field.

<template>
  <div :id="id" class="field-group">
    <input :disabled="$attrs.disabled" :name="fieldName" type="hidden" value="" autocomplete="off" />
    <checkbox v-for="optionValue in optionValues" :key="optionValue"
      :name="fieldName" :checked="isChecked(optionValue)"
      :value="optionValue" @change="$emit('update:modelValue', checkboxChange(optionValue, $event))"
      v-bind="$attrs">
      {{ optionValue }}
    </checkbox>
  </div>
</template>

<script>
import Checkbox from 'bootstrap/checkbox'

export default {
  inheritAttrs: false,
  props: ['id', 'optionValues', 'modelValue', 'name'],
  emits: ['update:modelValue'],
  computed: {
    fieldName() {
      return `${this.name}[]`
    }
  },
  methods: {
    isChecked(optionValue) {
      if(Array.isArray(this.modelValue)) {
        return this.modelValue.includes(optionValue)
      }
      else{
        return optionValue === this.modelValue
      }
    },
    checkboxChange(checkbox_value, checked) {
      if(checked) {
        if(!this.modelValue.includes(checkbox_value)){
          this.modelValue.push(checkbox_value)
        }
      }
      else {
        let found_index = this.modelValue.findIndex(val => val == checkbox_value)
        if(found_index > -1) {
          this.modelValue.splice(found_index, 1)
        }
      }

      return this.modelValue
    }
  },
  components: { Checkbox },
}
</script>

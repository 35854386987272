// Covers
//   @srs_1.1 @srs_1.5 @srs_1.9 @srs_5.1 @srs_6.1 @srs_7.1 @srs_7.5 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4 @srs_15.5 @srs_15.6 @srs_20.1 @srs_20.2 @srs_20.3 @srs_20.5 @srs_20.6 @srs_21.2 @srs_21.3 @srs_22.1 @srs_24.1

/*
 * Abstracts the delay in showing an indicator.
 */
export default class {
  constructor(callback, delay=250) {
    this.callback = callback
    this.delay = setTimeout(()=> this._run(), delay)
  }

  cancel() {
    if( !this.delay ) return
    clearTimeout(this.delay)
    this.delay = null
  }

  _run() {
    this.delay = null
    this.callback()
  }
}

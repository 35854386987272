// Covers
//   @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

const callbacks = {}
const owners = {}

export default function(currentFieldId, callbackId, callback) {
  const formId = formIdFor(currentFieldId)

  callbacks[formId] ??= {}
  owners[formId] ??= {}

  callbacks[formId][callbackId] = ()=> new Promise(callback)
  owners[formId][callbackId] = currentFieldId
}

export function clearPreviousCallbacksFor(currentFieldId) {
  const formId = formIdFor(currentFieldId)

  for( let [callbackId, fieldId] of Object.entries(owners[formId] || {}) ) {
    if( fieldId != currentFieldId ) continue

    delete owners[formId][callbackId]
    delete callbacks[formId][callbackId]
  }
}

async function runCallbacks(formId) {
  if( !formId ) return // Not a success form submission

  const formCallbacks = Object.values(callbacks[formId] || {})
  for( let i=0; i<formCallbacks.length; i++ )
    await formCallbacks[i]()
  delete callbacks[formId]
}

document.addEventListener('turbo:submit-end', ({ detail: { formSubmission: { result: { fetchResponse } } } })=> {
  if( !fetchResponse.succeeded ) return

  // Introduce delay here to ensure form response is fully processed before
  // and ready for any additions by hooks.
  setTimeout(()=> { runCallbacks(fetchResponse.header('X-Form-Success')) }, 50)
})

function formIdFor(currentFieldId) {
  const formId = document
    .getElementById(currentFieldId)
    ?.closest('form')
    ?.querySelector("[name=form_id]")
    ?.value

  if( !formId ) throw `Form ID not determinable for ${currentFieldId}`

  return formId
}

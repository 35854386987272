Covers
  @srs_15.6

Root component for order search screen. Displays list of orders, provides
search options to filter list.

<template>
  <div>
    <filters>
      <div class="row">
        <search v-model="query.search">
          <ul class="list-unstyled">
            <li>Order number</li>
            <li>Patient name</li>
            <li>Customer name</li>
            <li>Sample ID</li>
            <li>Searchable Custom Fields</li>
          </ul>

          <p>
            Search dates using format mm/dd/yyyy or yyyy-mm-dd.
            Example 11/05/1977
          </p>
        </search>
        <options-filter v-if="orderTypeOptions.length"
          label="Order Type(s)" multiple="multiple"
          v-model="query.order_type_id_in" :options="orderTypeOptions" />
      </div>

      <div class="row">
        <options-filter label="State(s)" multiple="multiple"
          v-model="query.state_in" :options="stateOptions" />
        <options-filter label="Delivery Type(s)" multiple="multiple"
          v-model="query.delivery_type_in" :options="deliveryTypeOptions" />
        <options-filter label="Organization(s)" multiple="multiple"
          v-model="query.organization_id_in" :options="organizationOptions" />
      </div>

      <date-filter label="Created On"
        includeBlank="true"
        v-model:at-gteq="query.created_at_gteq"
        v-model:at-lteq="query.created_at_lteq" />
    </filters>

    <results initial-uri="orders" :query="query" ref="results">
      <template #default="{ rows }">
        <div class="row row-cols-sm-1 row-cols-lg-2 row-cols-xl-3">
          <div v-for="order in rows" :key="order.id" class="col my-3">
            <item v-if="order.order_type_id" :order="order" />
            <stub v-else :order="order" />
          </div>
        </div>
      </template>
    </results>
  </div>
</template>

<script>
import Filters from 'index/filters'
import Search from 'index/filter/search'
import DateFilter from 'index/filter/date'
import OptionsFilter from 'index/filter/options'
import Results from 'index/results'
import QueryPersistence from 'index/query_persistence'
import Item from './item'
import Stub from './stub'

export default {
  mixins: [QueryPersistence],
  props: [
    'stateOptions', 'defaultStates', 'orderTypeOptions',
    'deliveryTypeOptions', 'organizationOptions'
  ],
  components: { Filters, Search, OptionsFilter, DateFilter, Results, Item, Stub },
  data() {
    return {
      query: this.fromUrlWithDefaults({
        search: '',
        state_in: this.defaultStates,
        order_type_in: null,
        delivery_type_in: null,
      })
    }
  },
}
</script>

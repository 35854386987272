Covers
  @srs_7.5 @srs_20.6 @srs_21.1 @srs_21.2 @srs_21.3 @srs_21.4

Renders a time field UDF/custom field. Requires `fieldSchema' and `name'

<template>
  <div class="input-group">
    <input type="time" class="form-control" v-bind="$attrs"
      :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
    <div class="input-group-append">
      <span class="input-group-text"><icon /></span>
    </div>
  </div>
</template>

<script>
import Icon from 'bootstrap-icons/icons/clock.svg'

export default {
  inheritAttrs: false,
  props: ['modelValue'],
  emits: ['update:modelValue'],
  components: { Icon },
}
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = { class: "col-1 text-right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_errors = _resolveComponent("errors")
  const _component_delete_button = _resolveComponent("delete-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_errors, {
      errors: $props.item.errors
    }, null, 8 /* PROPS */, ["errors"]),
    _createElementVNode("div", {
      class: _normalizeClass(["row", { odd: $options.odd, even: $options.even, 'bg-danger text-white': $options.hasErrors }])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString($props.item.unit_number), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.item.product_code), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString($props.item.blood_type), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_5, _toDisplayString($props.item.formattedExpirationAt()), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_delete_button, {
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('delete-item', $props.item)))
        })
      ])
    ], 2 /* CLASS */)
  ]))
}
Covers
  @srs_20.5

Renders given `href` pointing to a PDF on screen using canvas elements.
Rendering is responsive and resizes to match width of container. To contrain
resize use container.

Based on:
* https://github.com/mozilla/pdf.js/blob/master/examples/webpack/main.js
* https://github.com/arkokoley/pdfvuer/blob/master/src/Pdfvuer.vue

<template>
  <overlay :show="loading || rendering">
    <div v-bind:class="{ rendered: rendered }" class="pdf-container">
      <canvas v-for="i in pages.length" :key="i" v-show="pages[i-1]" ref="pages" class="my-3 shadow-sm" />
      <resize-sensor @resize="renderAll" />
    </div>
  </overlay>
</template>

<script>
import { markRaw } from 'vue'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf'

import ResizeSensor from 'vue-resize-sensor'
import { debounce } from 'debounce'

import Overlay from 'overlay'

GlobalWorkerOptions.workerPort = new Worker('/assets/pdf.worker.js');

export default {
  props: ['href'],
  components: { ResizeSensor, Overlay },
  created() { this.debouncedReload = debounce(this.reload, 500) },
  data() {
    return {
      doc: null,
      pages: [],
      loading: true,
      rendering: false,
    }
  },
  watch: {
    href() { this.debouncedReload() },
  },
  computed: {
    rendered() { return !this.loading && !this.rendering },
  },
  async mounted() { this.reload() },
  methods: {
    async reload() {
      this.loading = true
      this.doc = null
      this.pages = []

      try {
        this.doc = markRaw(await getDocument(this.href).promise)
      } catch (e) {
        // Failure to get the document is not 100% a problem. Timing (especially
        // in integration tests) can cause the document to become unavailable
        // while the request is being made. Instead log to console.
        if( e ) console.log(e.message)
        this.loading = false
        return
      }

      this.pages = Array(this.doc.numPages).fill(undefined)

      const loaders = []
      for (let pageNum = 0; pageNum < this.pages.length; pageNum++)
        loaders.push(this.loadPage(pageNum))

      // Allow loading all pages as the same time but wait for them to all
      // be done so we can indicate the loading is finished
      await Promise.all(loaders)
      this.loading = false
    },

    async loadPage(pageNum) {
      const page = await this.doc.getPage(pageNum+1)
      this.pages[pageNum] = markRaw(page)
    },

    async renderAll() {
      // Guard to prevent rapid resize from re-rending while last render is still happening
      if( this.rendering ) return
      this.rendering = true

      const renderings = []
      for (let pageNum = 0; pageNum < this.pages.length; pageNum++)
        renderings.push(this.render(pageNum))

      // Allow the pages to render at the same time but wait for them to finish
      await Promise.all(renderings)

      this.rendering = false
    },

    render(pageNum) {
      const canvas = this.$refs.pages[pageNum]
      if( !canvas ) return

      const page = this.pages[pageNum]
      if( !page ) return

      // Viewport scaled at 1 to get the natural width
      let viewport = page.getViewport({ scale: 1 })

      // Calculate the scale to take up 100% of the container width
      const containerWidth = canvas.parentNode.offsetWidth
      const pageWidth = viewport.width
      const scale = containerWidth / pageWidth

      // Viewport scaled at calculated size
      viewport = page.getViewport({scale: scale})

      // Set the canvas to hold the whole viewport
      canvas.width = viewport.width
      canvas.height = viewport.height

      // Paint the page on the canvas
      const context = canvas.getContext("2d")
      return page.render({ canvasContext: context, viewport }).promise
    },
  }
}
</script>

Covers
  @srs_15.6

Displays stubbed order to let user import

<template>
  <card>
    <template #title>
      Order ID {{ order.order_number }}
    </template>

    <dl v-if="order.delivery" class="card-details-header">
      <dt>Organization</dt>
      <dd>{{ order.organization_name }}</dd>

      <dt>Delivery</dt>
      <dd>
        {{ order.delivery }}

        <span v-if="order.delivery == 'STAT'" class="text-danger">
          <stat-icon />
        </span>
      </dd>
    </dl>

    <dl v-if="order.customer_name">
      <dt>Customer</dt>
      <dd>{{ order.customer_name }}</dd>
    </dl>

    <p>Order ready to be imported.</p>

    <view-button :href="`orders/${order.id}`" class="btn-block">Import</view-button>
  </card>
</template>

<script>
import ViewButton from 'bootstrap/view_button'
import Card from 'bootstrap/card'
import StatIcon from 'bootstrap-icons/icons/exclamation-triangle-fill.svg'

export default {
  props: ['order'],
  components: { ViewButton, Card, StatIcon },
}
</script>

import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "container",
      class: _normalizeClass(`language-javascript border text-monospace`),
      style: _normalizeStyle(`height: ${$props.height}; padding: 0.25rem`)
    }, null, 4 /* STYLE */)
  ]))
}
Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

For situtations where the item being sorted is too big to be dragged around
but at the same time you want something more user-friendly than the user
manually typing in sort numbers.

The `items` given to this component are listed on a main screen. A "Sort" button
activate this modal which shows that same list of items but in a much simpler
UI. Just a label for each item is shown making it small to drag around.

As the user drags the items around in this modal is changes the underlying
data which is the same data the primary UI is using therefore changing that
sort order.

The changes are immediate. It does not queue them up and apply when you close
this modal.

<template>
  <modal :id="id" ref="modal">
    <template #header>Sort {{ typeLabel }}</template>

    <container @drop="updateSort" tag="ul" class="list-group">
      <draggable v-for="item in items" :key="item[itemLabelAttr]" tag="li" class="list-group-item pl-0">
        <span class="inline-block p-3 border bg-light mr-3"><icon /></span>
        {{ item[itemLabelAttr] }}
      </draggable>
    </container>

    <template #footer>
      <styled-button priority="primary" @click="close">Close</styled-button>
    </template>
  </modal>
</template>

<script>
import { Container, Draggable } from 'vue3-smooth-dnd'
import Icon from 'bootstrap-icons/icons/arrow-down-up'

import Modal from 'bootstrap/modal'
import StyledButton from 'bootstrap/styled_button'

export default {
  props: ['id', 'typeLabel', 'items', 'itemLabelAttr'],
  components: { Modal, StyledButton, Container, Draggable, Icon },
  methods: {
    updateSort({ removedIndex, addedIndex }) {
      const item = this.items.splice(removedIndex, 1)[0]
      this.items.splice(addedIndex, 0, item)
    },

    close() { this.$refs.modal.close() },
  }
}
</script>

// Covers:
//   @srs_1.1 @srs_2.1 @srs_2.2 @srs_3.1 @srs_5.1 @srs_8.1 @srs_9.1 @srs_12.1 @srs_15.3 @srs_15.4

import $ from 'jquery'

// Side effects to ensure bootstrap is initialized and to install on jQuery object
import 'bootstrap'

// Side effects that install arrive on global objects
import 'arrive'

// Side effects that install select2 on jQuery object
import select2 from 'select2'
select2()

// Enhances file input a bit to restore dynamic behavior hidden by bootstrap
// as well as add some extras. What Bootstrap recommends.
import CustomFileInput from 'bs-custom-file-input'

import Toast from 'bootstrap/toast'
Toast.activateAll()

document.arrive('.site-version', { existing: true }, el => $(el).tooltip({
  html: true,
  title: $('.site-copyright').html(),
  template: `<div class="tooltip copyright-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>`,
  boundary: 'viewport',
}))

document.arrive('[data-toggle="tooltip"]', { existing: true }, el => $(el).tooltip())
document.arrive('[data-toggle="popover"]', { existing: true }, el => $(el).popover())

document.arrive('select[multiple]', { existing: true }, el => {
  $(el)
    // Activate select2 using theme to look like regular bootstrap 4 select lists
    .select2({theme: 'bootstrap4', width: '100%'})

    // select2 outputs fake `jQuery` events rather than real DOM events. This
    // makes the changes not detectable to non-jQuery systems (such as Vue).
    // This watches for changes and emits a real DOM event. This may mean a
    // jQuery listener hears the change event twice but that should be no harm
    .on('select2:select select2:unselect', function() {
      const domEvent = new Event('change', { bubbles: true, cancelable: true })
      this.dispatchEvent(domEvent)
    })
})

document.arrive('.custom-file input[type="file"]', { existing: true }, el => {
  // Assumes all file inputs have an id
  CustomFileInput.init(`#${el.id}`)
})

document.addEventListener('click', (event)=> {
  const button = event.target.closest('[type=submit], mwc-fab')
  if( !button ) return;

  const form = event.target.closest('form')
  if( !form ) return;
  if( form.reportValidity() ) return

  form.classList.add('was-validated')
  event.stopImmediatePropagation()
  event.preventDefault()
}, true)

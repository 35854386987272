import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-toolbar-right"
}
const _hoisted_2 = ["^disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_panel = _resolveComponent("panel")
  const _component_icon = _resolveComponent("icon")
  const _component_validate_only_form = _resolveComponent("validate-only-form")

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$slots.actions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "actions")
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_validate_only_form, {
      onSubmit: _cache[0] || (_cache[0] = $event => (_ctx.$emit('submit')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_panel, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "params")
          ]),
          _: 3 /* FORWARDED */
        }),
        _renderSlot(_ctx.$slots, "default"),
        _createElementVNode("mwc-fab", {
          label: "Save",
          "^disabled": $props.isSubmittable ? undefined : true
        }, [
          _createVNode(_component_icon, { slot: "icon" })
        ], 8 /* PROPS */, _hoisted_2)
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}
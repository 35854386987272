import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_pdf_viewer = _resolveComponent("pdf-viewer")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_pdf_viewer, {
      href: $props.href,
      ref: "viewer"
    }, null, 8 /* PROPS */, ["href"]),
    ($data.testEnv)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          class: "invisible",
          src: $options.htmlHref,
          ref: "hiddenContent",
          id: "report-content"
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true)
  ]))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b48180a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "col-md-10 col-lg-9 col-xl-6" }
const _hoisted_3 = { class: "card my-2" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "media" }
const _hoisted_7 = { class: "figure mr-3 mb-0 text-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "figure-caption" }
const _hoisted_10 = { class: "media-body" }
const _hoisted_11 = {
  key: 0,
  class: "font-weight-bold"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, [
            _createTextVNode(_toDisplayString($options.username) + " ", 1 /* TEXT */),
            _createElementVNode("span", {
              class: _normalizeClass($options.actionClass)
            }, _toDisplayString($options.actionDesc), 3 /* TEXT, CLASS */),
            _createTextVNode(" " + _toDisplayString($options.recordTitle), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("figure", _hoisted_7, [
              _createElementVNode("img", {
                src: $options.photoUrl,
                class: "figure-img img-fluid",
                alt: "Profile Photo"
              }, null, 8 /* PROPS */, _hoisted_8),
              _createElementVNode("figcaption", _hoisted_9, [
                _createElementVNode("small", null, _toDisplayString($options.formattedChangedAt), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              ($props.audit.comment)
                ? (_openBlock(), _createElementBlock("pre", _hoisted_11, "                " + _toDisplayString($props.audit.comment) + "\n              ", 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.audit.changes, (change) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent($options.changeTypeFor(change)), {
                  key: change.attr,
                  change: change
                }, null, 8 /* PROPS */, ["change"]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ])
      ])
    ])
  ]))
}
Covers
  @srs_22.1 @srs_23.1 @srs_24.1 @srs_25.1

Vue component for managing a list of field_schema for a UserDefinedField in the application.
loop over elements in the field_schema_list property and render FieldSchema components
provide new field and remove field buttons

<template>
  <div>
    <input v-if="fieldName" type="hidden" :name="fieldName" :value="JSON.stringify(list)" />

    <div class="pb-3 d-flex flex-row justify-content-between">
      <new-button tag="button" @click="addFieldSchema">
        Add Custom Input
      </new-button>

      <styled-button
        v-if="list.length > 1"
        icon="sort-down" priority="secondary"
        data-toggle="modal" data-target="#udf-sorter">
        Sort
      </styled-button>
    </div>

    <div class="field-schema-list">
      <field-schema v-for="(fieldSchema, index) in list" :key="fieldSchema.id"
        v-model:field-schema="list[index]" :needs-delete-button="true"
        :visiblity-types-allowed="visibilityTypesAllowed"
        :option-lists="optionLists" @delete-schema="removeFieldSchema" />
    </div>

    <sorting-modal id="udf-sorter" type-label="User Defined Fields" :items="list" item-label-attr="title" />
  </div>
</template>

<script>
// Components
import StyledButton from 'bootstrap/styled_button'
import NewButton from 'bootstrap/new_button'
import SortingModal from 'sorting_modal'
import FieldSchema from './field_schema'

// Models
import BaseField from '../field_types/base'

export default {
  components: { FieldSchema, NewButton, StyledButton, SortingModal },
  props: {
    'fieldSchemaArray': {},
    'fieldName': {},
    'visibilityTypesAllowed': { default() { return ['Summary', 'Report', 'Search Results'] } },
    'optionLists': {},
  },
  data() {
    return {
      list: this.fieldSchemaArray || [],
    }
  },
  methods: {
    addFieldSchema() { this.list.push(new BaseField({}).payload()) },
    removeFieldSchema(event) {
      let found_index = this.list.findIndex(schema => schema.id == event.id)
      if(found_index > -1) {
        this.list.splice(found_index, 1)
      }
    },
  },
}
</script>

Covers:
  @srs_8.1 @srs_9.1 @srs_12.1 @srs_14.1 @srs_14.2 @srs_14.5

Displays a specific inventory unit being operated on for a transaction.

<template>
  <div class="item">
    <errors :errors="item.errors" />

    <div class="row" :class="{ odd, even, 'bg-danger text-white': hasErrors }">
      <div class="col-3">{{ item.unit_number }}</div>
      <div class="col-3">{{ item.product_code }}</div>
      <div class="col-2">{{ item.blood_type }}</div>
      <div class="col-3">{{ item.formattedExpirationAt() }}</div>
      <div class="col-1 text-right">
        <delete-button @click="$emit('delete-item', item)" />
      </div>
    </div>
  </div>
</template>

<script>
import DeleteButton from 'bootstrap/delete_button'

import Errors from './errors'

export default {
  props: ['item', 'index'],
  emits: ['delete-item'],
  computed: {
    odd() { return !this.hasErrors && this.index % 2 != 0 },
    even() { return !this.hasErrors && this.index % 2 == 0 },
    hasErrors() { return this.item.errors.length > 0 },
  },
  components: { DeleteButton, Errors },
}
</script>

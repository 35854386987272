import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mb-3"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_field_schema = _resolveComponent("field-schema")
  const _component_new_button = _resolveComponent("new-button")

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.fieldSchema)
      ? (_openBlock(), _createBlock(_component_field_schema, {
          key: 0,
          fieldSchema: $props.fieldSchema,
          "needs-delete-button": true,
          visibilityTypesAllowed: $props.visibilityTypesAllowed,
          "option-lists": $props.optionLists,
          onDeleteSchema: _cache[0] || (_cache[0] = $event => (_ctx.$emit('delete-schema', $props.fieldSchema))),
          "onUpdate:fieldSchema": _cache[1] || (_cache[1] = $event => (_ctx.$emit('update:field-schema', $event)))
        }, null, 8 /* PROPS */, ["fieldSchema", "visibilityTypesAllowed", "option-lists"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_new_button, {
            tag: "button",
            onClick: _cache[2] || (_cache[2] = $event => (_ctx.$emit('init-schema')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Add Custom Input ")
            ]),
            _: 1 /* STABLE */
          })
        ]))
  ]))
}
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent($props.tag), _mergeProps(_ctx.$attrs, {
    class: ["btn", [$options.buttonClasses, $options.sizeButtonClasses, $options.iconButtonClasses]],
    type: $options.buttonType,
    onClick: $options.click
  }), {
    default: _withCtx(() => [
      _createElementVNode("span", null, [
        ($props.icon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.iconComponent), { key: 0 }))
          : _createCommentVNode("v-if", true)
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["type", "onClick", "class"]))
}
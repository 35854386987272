import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b9c43af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-group-item" }
const _hoisted_2 = { class: "row no-gutters" }
const _hoisted_3 = { class: "col-6 col-xl-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "col-6 col-xl-4 pl-1" }
const _hoisted_6 = { class: "col-6 col-xl-2 pl-xl-1" }
const _hoisted_7 = { class: "col-6 col-xl-2 pl-1" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_delete_button = _resolveComponent("delete-button")
  const _component_field = _resolveComponent("field", true)

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_delete_button, {
      onClick: _cache[0] || (_cache[0] = $event => (_ctx.$emit('remove')))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_field, { label: "Object" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($props.field.object) = $event)),
              class: "custom-select-sm"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.objects, (object) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: object[0],
                  value: object[0]
                }, _toDisplayString(object[1]), 9 /* TEXT, PROPS */, _hoisted_4))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512 /* NEED_PATCH */), [
              [_vModelSelect, $props.field.object]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_field, { label: "Field" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($props.field.field) = $event)),
              class: "form-control-sm"
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, $props.field.field]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_field, { label: "X" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($props.field.x) = $event)),
              class: "form-control-sm"
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, $props.field.x]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_field, { label: "Y" }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($props.field.y) = $event)),
              class: "form-control-sm"
            }, null, 512 /* NEED_PATCH */), [
              [_vModelText, $props.field.y]
            ])
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}